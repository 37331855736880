<template>
  <div>
    <feather-icon title="View Details" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="displayRecord" />
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CellRendererSettingsActions',
  data () {
    return {
      
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.$router.push({ name: 'admin-supplier-settings-edit', 
        params: { 
          supplierId: this.params.data.supplierId,
          settingName: this.params.data.settingName
        }
      })
    },
    displayRecord () {
      this.$router.push({ name: 'admin-supplier-settings-view', 
        params: { 
          supplierId: this.params.data.supplierId,
          settingName: this.params.data.settingName
        }
      })
    }
  }
}
</script>
