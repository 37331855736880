<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button @click="onAdd" class="mr-4 sm:mb-0 mb-2">Add</vs-button>
      </div>
    </div>

    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100 ag-grid-table dynamic-height-supplier-settings-grid my-4"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="settingsData"
      :gridOptions="gridOptions"
      rowSelection="single"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      @selection-changed="onSelectionChanged"
    >
    </ag-grid-vue>
    <vs-pagination
      :total="totalPages"
      :max="5"
      v-model="currentPage" />
  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererSettingsCheckbox from './cell-renderers/CellRendererSettingsCheckbox.vue'
import CellRendererSettingsActions from './cell-renderers/CellRendererSettingsActions.vue'
import Vue from "vue"

export default {
  components: {
    AgGridVue,
    CellRendererSettingsCheckbox,
    CellRendererSettingsActions
  },
  props: {
    supplierId: { required: true }
  },
  data () {
    return {
      settingsData: [],
      searchQuery: '',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererSettingsCheckbox,
        CellRendererSettingsActions
      }
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  created () {

    this.$vs.loading();
      this.$http.get(`api/admin/suppliers/${this.supplierId}/settings`)
      .then(response => {
        this.settingsData = response.data
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    
    // this.settingsData = [
    //   { id: 1, name: "Agent Name", defaultValue: "DTWebPlatform", options: "", isOptionMultiple: false },
    //   { id: 2, name: "Brands", defaultValue: "Parking,Hotels", options: "Parking,Lounges,Hotels,FastTrack", isOptionMultiple: true }
    // ]

  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: '', width: 100, sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererSettingsActions) },
      { hide: true, field: 'supplierId' },
      { headerName: 'Setting Name', field: 'settingName', width: 180, suppressSizeToFit: true, filter: true },
      { headerName: 'Default Value', field: 'defaultValue', width: 180, suppressSizeToFit: true, filter: true },
      { headerName: 'Options', field: 'options', width: 160, suppressSizeToFit: true, filter: true },
      { headerName: 'Multiple', field: 'isOptionMultiple', sortable: true, suppressSizeToFit: true, width: 110, cellRendererFramework: Vue.extend(CellRendererSettingsCheckbox) }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }
  },

  methods: {
    onAdd () {
      this.$router.push({ name: 'admin-supplier-settings-add', params: { supplierId: this.supplierId }})
    },
    updateSearchQuery () {

    },
    onSelectionChanged () {

    }
  }
}
</script>

<style scoped>
  .dynamic-height-supplier-settings-grid {
    height: calc(var(--vh, 1vh) * 100 - 39rem);
  }

</style>